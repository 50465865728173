import './auth.scss';

const Auth = () => {
  return (
    <p className='auth'>
      Этот функционал может быть реализован в&nbsp;следующих обновлениях.
    </p>
  );
};

export default Auth;
